.wettkampfStyle {
  width: 98vw;
  /*min-height: 100vh;*/
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.wettkampfHeaderStyle {
  overflow-x: hidden;
}

.BoatFlag > img {
  object-fit: contain !important;
}
.ant-avatar > img {
  object-fit: contain !important;
}

.Werbebild {
  object-fit: contain !important;
  max-height: 300px !important;
  width: auto;
}

.CompetitionHeader {
  width: 99%;
}

.CompetitionHeader > .ant-image {
  width: 97vw;
  padding-left: 10px;
  padding-right: 10px;
}

.CompetitionFooter {
  width: 99%;
}

.CompetitionFooter > .ant-image {
  width: 97vw;
  padding-left: 10px;
  padding-right: 10px;
}

.CompListItem {
  display: unset;
}

.CompListItemDiv {
  display: block;
  border-color: lightgray;
  border-width: 2px;
  border-style: outset;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: -15px;
  width: 100%;
}

.CompListItemDiv:hover {
  background-color: rgba(150, 150, 150, 0.5);
}

.BoatsTable > tbody > tr:nth-child(2n) {
  background-color: rgba(150, 150, 150, 0.5);
}

.BoatsTable > tbody > tr > td {
  text-align: center;
}
.BoatsTable > tbody > tr > td:first-child {
  font-weight: 600;
}
.BoatTimeValue {
  font-weight: 600;
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    padding: unset;
  }

  .tableBoatRank {
    display: inline-block !important;
  }
  .tableBoatLane {
    display: inline-block !important;
    margin-left: 40px !important;
  }

  .tableBoatFlag {
    display: inline-block !important;
  }
  /*
  .BoatsTable > tbody > tr > td.tableBoatSplitTime:has(+ .tableBoatFinalTime) {
    display: inline !important;
  }

  .BoatsTable
    > tbody
    > tr
    > td.tableBoatSplitTime:has(+ .tableBoatFinalTime):after {
    content: "\A";
    white-space: pre;
  }
    */
  .tableBoatSplitTime {
    display: inline-block !important;
  }
  /*
  .tableBoatFinalTime::before {
    content: "\A";
    white-space: pre;
  }
  .tableBoatFinalTime {
    clear: left;
    float: left;
  }
    */

  .responsiveTable td.pivoted {
    padding-left: 70px !important;
  }

  .responsiveTable td .tdBefore {
    width: 80px;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 3px 16px !important;
}

.tableBoatFinalTime {
  padding-left: 5px;
  padding-right: 5px;
}

.tableBoatSplitTime {
  padding-left: 5px;
  padding-right: 5px;
}

.BoatsTable {
  border-bottom: 2px solid;
}

.CardWerbung > div > div > div > .ant-card-meta-title {
  white-space: pre-wrap !important;
  text-align: center;
}

.CardWerbung > div > div > div > .ant-card-meta-title > b {
  justify-content: center;
}

.CardWerbung > div > .ant-card-meta {
  justify-content: center;
  display: block;
}

.ant-collapse > .ant-collapse-item {
  border-color: rgba(150, 150, 150, 0.5);
}

.scattergraphTooltip {
  background-color: rgba(150, 150, 150, 1);
  border: 1px solid rgba(150, 150, 150, 0.5);
  padding: 5px;
  z-index: 100000;
}

.ant-select-clear {
  opacity: 1 !important;
}

.vis.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  transition: none;
}

#IMAS_LOGO_DIV > div {
  display: block;
}